import { useSelector } from "react-redux";

const settings = () => {
    const hostName = useSelector((state) => state?.host?.host);

    return { googleMaps: {
        apiKey : (hostName.includes('localhost') || hostName.includes('dlr')) ? 'AIzaSyCgD0kCpWRwexX06y6hIVj2gk_9cZ7vjxw' : 'AIzaSyBfT5mCCIlmX4G6ujaAJGduWXPR7ghUre4'
        //Dlr & localhost api key 'AIzaSyCgD0kCpWRwexX06y6hIVj2gk_9cZ7vjxw'
        // production & uat api key: 'AIzaSyBfT5mCCIlmX4G6ujaAJGduWXPR7ghUre4'
        //Dev mode api key AIzaSyDlhj8CTf0uECoIQAsCNj63yULYURvy0To
    }}
}

export default settings;
import GoogleMapReact from 'google-map-react';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import MapStyles from './map-styles';
import { Tooltip, Zoom } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {isLinkAbsolute, isMobile, isTablet} from "../../util/DataUtil";
import settings from '../../settings/settings-development';

const RegionMarker = ({ children }) => children;
const SubRegionMarker = ({children}) => children;
const MetroMarker = ({ children }) => children;
const FacilityMarker = ({ children }) => children;
const Marker = ({ children }) => children;

const Map = ({
        latitude, 
        longitude, 
        zoom, 
        renderMarkers, 
        metroMarkers, 
        regionalMap, 
        maxZoom, 
        metroMap, 
        width, 
        blockTypeClass, 
        globalMap = false, 
        setCurrentZoom, 
        currentZoom, 
        currentRegion, 
        setCurrentRegion, 
        filterText, 
        filterTextTethered, 
        lenghtOfFilters, 
        setIsToolTipActive,
        isToggled
    }) => {
    const [mapStyles, setMapStyles] = useState(MapStyles);
    const mapRef = useRef();
    const router = useRouter();
    const [hoverIndex , setHoverIndex] = useState(null);
    const [isToolTipHovered, setIsToolTipHovered] = useState(false);
    const [mapInstance, setMapInstance] = useState(null);
    const [defaultProps, setDefaultProps] = useState({
        center: {
            lat: latitude,
            lng: longitude
        },
        zoom,
        gestureHandling: 'greedy'
    });
    const [key, setKey] = useState(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const [canOpenTooltip, setCanOpenTooltip] = useState(true);

    useEffect(() => {
        let timer;
    
        if (canOpenTooltip) {
            timer = setTimeout(() => {
                setShowTooltip(true);
                setCanOpenTooltip(false);
            }, 400);
        }
    
        return () => clearTimeout(timer);
    }, [canOpenTooltip]);

    useEffect(() => {
        setDefaultProps(prevState => ({
            ...prevState,
            zoom: width < 575 ? 1 : 2
        }));

        // hide current region on mobile
        if(width > 576){
            setCurrentRegion('');
        }
    }, [width])

    const handleTooltipClose = () => {
        setShowTooltip(false);
        setCanOpenTooltip(true);
    };

    // useEffect(() => {
    //     if(globalMap && mapRef.current){
    //         switch (currentRegion) {
    //             case 'americas':
    //                 mapRef.current.setZoom(3);
    //                 mapRef.current.panTo({ lat: Number('17.63762322142215'), lng: Number('-74.13317741314825') });
    //                 break;
    //             case 'apac':
    //                 mapRef.current.setZoom(3);
    //                 mapRef.current.panTo({ lat: Number('7.149192636813368'), lng: Number('100.95977982859853') });
    //                 break;
    //             case 'emea':
    //                 mapRef.current.setZoom(3);
    //                 mapRef.current.panTo({ lat: Number('40.67292343199639'), lng: Number('23.17810369294859') });
    //                 break;
    //             case 'north-america':
    //                 mapRef.current.setZoom(4);
    //                 mapRef.current.panTo({ lat: Number('37.50477291319143'), lng: Number('-95.8836806475338') });
    //                 break;
    //             case 'south-america':
    //                 mapRef.current.setZoom(4);
    //                 mapRef.current.panTo({ lat: Number('-18.913186887815108'), lng: Number('-57.94636087477352') });
    //                 break;
    //             case 'europe':
    //                 mapRef.current.setZoom(4);
    //                 mapRef.current.panTo({ lat: Number('50.90434747644453'), lng: Number('13.114154724145223') });
    //                 break;
    //             case 'africa':
    //                 mapRef.current.setZoom(4);
    //                 mapRef.current.panTo({ lat: Number('-15.936364474302567'), lng: Number('24.215315992487145') });
    //                 break;
    //             case 'australia':
    //                 mapRef.current.setZoom(4);
    //                 mapRef.current.panTo({ lat: Number('-30.134487897082696'), lng: Number('135.00047061171165') });
    //                 break;
    //             case 'asia':
    //                 mapRef.current.setZoom(4);
    //                 mapRef.current.panTo({ lat: Number('18.82786314961729'), lng: Number('117.73094021224544') });
    //                 break;
    //         default:
    //             break;
    //         }           
    //     }
    // },[currentRegion])

    useEffect(() => {
        if (globalMap && mapRef.current) {
            const isSmallScreen = width < 700;

            const setMapView = (lat, lng, zoom) => {
                if (isSmallScreen) {
                    // mapRef.current.setZoom(1);
                    // mapRef.current.setZoom(currentZoom - 2 >= 1 ? currentZoom - 2 : 1);
                    mapRef.current.setZoom(Math.max(currentZoom - 2, 1));
                    mapRef.current.panTo({ lat: Number(lat), lng: Number(lng) });
                    setTimeout(() => {
                        mapRef.current.setZoom(zoom);
                    }, 500);
                } else {
                    mapRef.current.setZoom(zoom);
                    mapRef.current.panTo({ lat: Number(lat), lng: Number(lng) });
                }
            };
    
            switch (currentRegion) {
                case 'americas':
                    setMapView('17.63762322142215', '-74.13317741314825', 3);
                    break;
                case 'apac':
                    setMapView('7.149192636813368', '100.95977982859853', 3);
                    break;
                case 'emea':
                    setMapView('40.67292343199639', '23.17810369294859', 3);
                    break;
                case 'north-america':
                    setMapView('37.50477291319143', '-95.8836806475338', 4);
                    break;
                case 'south-america':
                    setMapView('-18.913186887815108', '-57.94636087477352', 4);
                    break;
                case 'europe':
                    setMapView('50.90434747644453', '13.114154724145223', 4);
                    break;
                case 'africa':
                    setMapView('-15.936364474302567', '24.215315992487145', 4);
                    break;
                case 'australia':
                    setMapView('-30.134487897082696', '135.00047061171165', 4);
                    break;
                case 'asia':
                    setMapView('18.82786314961729', '117.73094021224544', 4);
                    break;
                default:
                    break;
            }
        }
    }, [currentRegion]);    

    useEffect(() => {
        setDefaultProps({
            center: {
                lat: latitude,
                lng: longitude
            },
            zoom: currentZoom,
            gestureHandling: 'greedy'
        })
        setKey(`${latitude}-${longitude}`);

        return () => {
            setDefaultProps({})
            setKey(null);
        }
    },[latitude, longitude, currentZoom])

    useEffect(() => {
        if(regionalMap || metroMap){
            if (mapInstance && renderMarkers?.length <= 3) {
              mapInstance.setZoom(currentZoom);
              mapInstance?.setOptions({minZoom: currentZoom});
            }else {
                mapInstance?.setOptions({minZoom: mapInstance.zoom});
            }
        }
      }, [renderMarkers?.length, mapInstance]);

    const mapOptions = {
        minZoom: (metroMap || regionalMap) ? undefined : currentZoom,
        maxZoom: maxZoom ? maxZoom : undefined,
        disableDefaultUI: globalMap ? false : true,
        styles: mapStyles,
        zoom: (metroMap || regionalMap) ? undefined : currentZoom,
        navigationControl: true,
        mapTypeControl: false,
        scaleControl: false,
        clickableIcons: false,
        // gestureHandling: 'greedy',
        fullscreenControl: false,
        zoomControl: width <= 416 ? false : true
    }

    useEffect(() => {
        if(regionalMap || metroMap){
            if (mapInstance && renderMarkers?.length === 1) {
              mapInstance.setZoom(currentZoom);
              mapInstance?.setOptions({minZoom: currentZoom});
            }else {
                mapInstance?.setOptions({minZoom: mapInstance.zoom});
            }
        }
      }, [renderMarkers?.length, mapInstance]);

    const calculateBounds = (map, maps) => {
        const bounds = new maps.LatLngBounds();
        renderMarkers.forEach(pin => {
            bounds.extend(new maps.LatLng(pin.latitude, pin.longitude));
        });
        map.fitBounds(bounds);

        if(renderMarkers?.length == 1){
            setCurrentZoom(12);
        }
      };

    const handleApiLoaded = (map, maps) => {
        map.setOptions(mapOptions);
        if(regionalMap || metroMap){
            calculateBounds(map, maps);
        }
        setMapInstance(map);
        if(map.getZoom() <= 4){
            map.setOptions({
                styles: [...mapStyles,{
                    "featureType": "administrative.country",
                    "elementType": "labels.text",
                    "stylers": [{"visibility": "off"}],
                },{
                    "featureType": "administrative.province",
                    "elementType": "labels.text",
                    "stylers": [{"visibility": "off"}],
                },
                    {
                        "featureType": "administrative.locality",
                        "elementType": "labels.text",
                        "stylers": [{"visibility": "off"}],
                    },
                ]
            })
        }
    }

    const onMapChange = ({zoom, bounds}) => {
        setCurrentZoom(zoom);
    }

    const detectZoomChange = (map, maps) => {

        map.addListener('zoom_changed', () => {
            let currentZoom = map.getZoom();

            if(globalMap){
                if(currentZoom <= 3){
                    // hide current region on mobile
                    // setCurrentRegion('');

                    if(width > 576){
                        setCurrentRegion('');
                    }
                }
                map.setOptions({
                    styles: [...mapStyles,{
                        "featureType": "administrative.country",
                        "elementType": "labels.text",
                        "stylers": [{"visibility": "off"}],
                    },{
                        "featureType": "administrative.province",
                        "elementType": "labels.text",
                        "stylers": [{"visibility": "off"}],
                    },
                    {
                        "featureType": "administrative.locality",
                        "elementType": "labels.text",
                        "stylers": [{"visibility": "off"}],
                    },
                    {
                        "featureType": "administrative.neighborhood",
                        "elementType": "labels.text",
                        "stylers": [{"visibility": "off"}],
                    },
                    {
                        "featureType": "administrative.land_parcel",
                        "elementType": "labels.text",
                        "stylers": [{"visibility": "off"}],
                    },
                    ]
                })
                return;
            }

            if(currentZoom > 4){
                map.setOptions({
                    styles: [...mapStyles,{
                        "featureType": "administrative.country",
                        "elementType": "labels.text",
                        "stylers": [{"visibility": "on"}],
                    },{
                        "featureType": "administrative.province",
                        "elementType": "labels.text",
                        "stylers": [{"visibility": "on"}],
                    },
                        {
                            "featureType": "administrative.locality",
                            "elementType": "labels.text",
                            "stylers": [{"visibility": "on"}],
                        },
                    ]
                })
            }else if(currentZoom <= 4){
                map.setOptions({
                    styles: [...mapStyles,{
                        "featureType": "administrative.country",
                        "elementType": "labels.text",
                        "stylers": [{"visibility": "off"}],
                    },{
                        "featureType": "administrative.province",
                        "elementType": "labels.text",
                        "stylers": [{"visibility": "off"}],
                    },
                        {
                            "featureType": "administrative.locality",
                            "elementType": "labels.text",
                            "stylers": [{"visibility": "off"}],
                        },
                    ]
                })
            }
        });
    }

    const renderTooltip = (marker) => {
        let filteredItems = [];

        if(marker.avaliableFilters?.length == 0) return;

        for(let filter of marker.avaliableFilters){
            let item;
            item = filter.name.includes('(') ? filter['name'].substring(0, filter['name'].indexOf('(')) : item = filter['name'];

            if (!filteredItems.includes(item)) {
                filteredItems.push(item);
            }
        }

        return filteredItems?.map((name, index) => {
            return (
                <div key={`filter-index-${index}`} className={isToggled ? 'filter tethered' : 'filter'}>
                    <img alt='map-pin' className={`pin-icon ${marker.regionColor}`} src='/images/checkmark-icon.svg' />
                    <span className='filter-name'>{name}</span>
                </div>
            )
        });
    }

    const renderTooltipTethered = (marker) => {
        let filteredItems = [];

        if(marker.availableFiltersTethered?.length === 0){
            return;
        }

        for(let filter of marker.availableFiltersTethered){
            let item;
            item = filter.name.includes('(') ? filter['name'].substring(0, filter['name'].indexOf('(')) : item = filter['name'];

            if (!filteredItems.includes(item)) {
                filteredItems.push(item);
            }
        }

        return filteredItems?.map((name, index) => {
            return (
                <div key={`filter-index-${index}`} className={marker.availableFiltersTethered.length > 0 ? 'filter tethered' : 'filter'}>
                    <img alt='map-pin' className={`pin-icon ${marker.regionColor}`} src='/images/checkmark-icon.svg' />
                    <span className='filter-name'>{name}<span className='tethered-font'>{"(T)"}</span></span>
                </div>
            )
        });
    }

    const renderLocations = (markers) => {
        if(typeof markers === 'object' && !Array.isArray(markers)) return markers;
        if(!markers) return;

        return markers.map((marker, index) => {
            if(!marker.latitude || !marker.longitude) return;

            if(marker.type === 'region' && currentZoom < 3){
                if(marker.regionColor === 'apac'){
                    return <RegionMarker key={`region-${marker.regionColor}-${index}`} lat={marker.latitude} lng={marker.longitude}>
                        <div style={{display:'flex',flexDirection:'column'}} onClick={() => {
                            mapRef.current.setZoom(3);
                            setCurrentRegion('apac');
                            mapRef.current.panTo({lat: Number('7.149192636813368'), lng: Number('100.95977982859853')});
                        }} className={`region-marker ${marker.regionColor}`}>
                            <span>{marker?.label ? marker.label: marker.name}</span>
                            <span>{marker?.numberOfMetros?.apac ? `(${marker.numberOfMetros.apac})` : lenghtOfFilters > 0 ? '(0)' : ''}</span>
                        </div>
                    </RegionMarker>
                }else if(marker.regionColor === 'emea'){
                    return <RegionMarker key={`region-${marker.regionColor}-${index}`} lat={marker.latitude} lng={marker.longitude}>
                        <div style={{display:'flex',flexDirection:'column'}} onClick={() => {
                            mapRef.current.setZoom(3);
                            setCurrentRegion('emea');
                            mapRef.current.panTo({lat: Number('40.67292343199639'), lng: Number('23.17810369294859')});
                        }} className={`region-marker ${marker.regionColor}`}>
                            <span>{marker?.label ? marker.label: marker.name}</span>
                            <span>{marker?.numberOfMetros?.emea ? `(${marker.numberOfMetros.emea})` : lenghtOfFilters > 0 ? '(0)' : ''}</span>
                        </div>
                    </RegionMarker>
                }
                return <RegionMarker key={`region-${marker.regionColor}-${index}`} lat={marker.latitude} lng={marker.longitude}>
                    <div style={{display:'flex',flexDirection:'column'}} onClick={() => {
                        mapRef.current.setZoom(3);
                        setCurrentRegion('americas');
                        mapRef.current.panTo({lat: Number('17.63762322142215'), lng: Number('-74.13317741314825')});
                    }} className={`region-marker ${marker.regionColor}`}>
                        <span>{marker?.label ? marker.label: marker.name}</span>
                        <span>{marker?.numberOfMetros?.americas ? `(${marker.numberOfMetros.americas})` : lenghtOfFilters > 0 ? '(0)' : ''}</span>
                    </div>
                </RegionMarker>
            }
            else if(marker.type === 'sub-region' && currentZoom == 3){
                return <SubRegionMarker key={`region-${marker.regionColor}-${index}`} lat={marker.latitude} lng={marker.longitude}>
                    <div style={{display:'flex',flexDirection:'column'}} onClick={() => {
                        mapRef.current.setZoom(4);
                        if(marker.name == 'North America'){
                            setCurrentRegion('north-america');
                            mapRef.current.panTo({lat: Number('37.50477291319143'), lng: Number('-95.8836806475338')});
                            return;
                        }
                        else if(marker.name == 'South America'){
                            setCurrentRegion('south-america');
                            mapRef.current.panTo({lat: Number('-18.913186887815108'), lng: Number('-57.94636087477352')});
                            return;
                        }
                        else if(marker.name == 'Europe'){
                            setCurrentRegion('europe');
                            return;
                        }
                        else if(marker.name == 'Africa'){
                            setCurrentRegion('africa');
                            mapRef.current.panTo({lat: Number('-4.936364474302567'), lng: Number('24.215315992487145')});
                            return;
                        }
                        else if(marker.name == 'Asia'){
                            mapRef.current.panTo({lat: Number('24.82786314961729'), lng: Number('117.73094021224544')});
                            setCurrentRegion('asia');
                            return;
                        }
                        else if(marker.name == 'Australia'){
                            setCurrentRegion('australia');
                            mapRef.current.panTo({lat: Number('-30.134487897082696'), lng: Number('135.00047061171165')});
                            return;
                        }
                    }} className={`sub-region-marker ${marker.regionColor}`}>
                        <span>{marker?.label ? marker.label : marker.name }</span>
                        <span>{marker?.regionKey && marker.numberOfMetros && marker?.numberOfMetros[`${marker.regionKey}`] > 0 ? `(${marker.numberOfMetros[`${marker.regionKey}`]})` : lenghtOfFilters > 0 ? '(0)' : ''}</span>
                    </div>
                </SubRegionMarker>
            }
            else if(marker.type === 'metro' && currentZoom == 4){
                return (
                    <MetroMarker key={`metro-${marker.regionColor}-${index}`} lat={marker.latitude} lng={marker.longitude}>
                    <Tooltip
                        leaveDelay={200}
                        enterDelay={300}
                        open={hoverIndex === index || isToolTipHovered === index ? showTooltip : false}
                        enterTouchDelay={0}
                        leaveTouchDelay={4000}
                        TransitionComponent={Zoom}
                        className='marker-tool-tip'
                        placement="top-start"
                        title={
                            <>
                            <div onMouseEnter={() => {setIsToolTipHovered(index); setIsToolTipActive(true)}} onMouseLeave={() => {setIsToolTipHovered(false);setIsToolTipActive(false);}} className='hover-helper-div top'></div>
                            <Link href={marker.link} passHref>
                                <a onMouseEnter={() => {setIsToolTipHovered(index); setIsToolTipActive(true)}} onMouseLeave={() => {setIsToolTipHovered(false);setIsToolTipActive(false);}} target={isLinkAbsolute(marker.link) ? '_blank' : '_self'} className={`info-window a-${blockTypeClass}-link ${marker.regionColor}`}>
                                    <div className='top'>
                                        <div className='left'>
                                            <span className='title'>{marker?.name}</span>
                                            <span className='sub-title'>{marker?.intro}</span>
                                        </div>
                                        <span className={`circle ${marker.regionColor}`}>{marker.numberOfFacilities}</span>
                                    </div>
                                    {
                                        marker?.avaliableFilters.length !== 0 && 
                                        <div className={'filter-container'}>
                                            <span className='filter-label'>{filterText}</span>
                                            <div className='filters'>
                                                { renderTooltip(marker) }
                                            </div>
                                        </div>
                                    }
                                    {
                                        (isToggled && marker?.availableFiltersTethered.length !== 0) &&
                                        <div className={'filter-container tethered-container'}>
                                            {/* <span className='filter-label'>{'Available Tethered (T) On-ramps:'}</span> */}
                                            <span className='filter-label'>{filterTextTethered}</span>
                                            <div className='filters'>
                                                { renderTooltipTethered(marker) }
                                            </div>
                                        </div>
                                    }
                                    <a className={marker.availableFiltersTethered.length > 0 ? 'marker-link tethered' : 'marker-link'}>{marker?.infoText}<img className="more-arrow" src="/images/learn-more-arrow.svg" /></a>
                                </a>
                            </Link>
                            <div onMouseEnter={() => {setIsToolTipHovered(index); setIsToolTipActive(true)}} onMouseLeave={() => {setIsToolTipHovered(false);setIsToolTipActive(false);}} className='hover-helper-div'></div>
                            </>
                        }
                    >
                    <div key={index} onMouseEnter={() => {setHoverIndex(index);setIsToolTipActive(true); handleTooltipClose()}} onMouseLeave={() => {setHoverIndex(null);setIsToolTipActive(false)}} onClick={() => {
                        router.push(marker.link);
                    }} className={`metro-marker ${marker.regionColor} ${hoverIndex === index || isToolTipHovered === index ? 'hover-active':''}`}>{hoverIndex === index || isToolTipHovered === index ? marker.name :marker?.shortCode}</div></Tooltip>
                    </MetroMarker>
                )
            }
        })
    }

    const renderMetros = (markers) => {
        if(!markers) return;

        return markers.map((marker, index) => {
            return  <Marker key={`region-${marker.regionColor}-${index}`} lat={marker.latitude} lng={marker.longitude}>
                <Tooltip
                    leaveDelay={200}
                    enterDelay={200}
                    enterTouchDelay={0}
                    leaveTouchDelay={4000}
                    TransitionComponent={Zoom}
                    className='marker-tool-tip'
                    placement='top-start'
                    title={
                        <Link href={marker.link} passHref>
                            <a target={isLinkAbsolute(marker.link) ? '_blank' : '_self'} className={`info-window a-${blockTypeClass}-link ${marker.regionColor}`}>

                                <div className='top'>
                                    <div className='left'>
                                        <span className='title'>{marker?.title}</span>
                                        <span className='sub-title'>{marker?.subTitle}</span>
                                    </div>
                                    <span className={`circle ${marker.regionColor}`}>{marker.numberOfFacilities}</span>
                                </div>
                                {
                                    marker?.avaliableFilters.length !== 0 && 
                                    <div className='filter-container'>
                                        <span className='filter-label'>{filterText}</span>
                                        <div className='filters'>
                                            {renderTooltip(marker)}
                                        </div>
                                    </div>
                                }
                                {
                                    (isToggled && marker?.availableFiltersTethered?.length !== 0) &&
                                    <div className={'filter-container tethered-container'}>
                                        {/* <span className='filter-label'>{'Available Tethered (T) On-ramps:'}</span> */}
                                        <span className='filter-label'>{filterTextTethered}</span>
                                        <div className='filters'>
                                            { renderTooltipTethered(marker) }
                                        </div>
                                    </div>
                                }

                                <a className={`marker-link`}>{marker?.infoText}<img className="more-arrow" src="/images/learn-more-arrow.svg" /></a>

                            </a>
                        </Link>
                    }>
                    <div
                        onMouseOver={() => {
                            // setIsOpen(true)
                            // mapRef.current.panTo({lat: marker.latitude, lng: marker.longitude})
                        }} className={`metro-marker ${marker.regionColor}`}>

                    </div>
                </Tooltip>
            </Marker>
        })
    }

    const renderFacilities = (markers) => {
        if(!markers || markers.length == 0) return;
        return markers.map((marker, index) => {
            return <Marker key={`facility-index-${index}`} lat={marker.latitude} lng={marker.longitude}>
                <div
                    onClick={() => {
                        if (isLinkAbsolute(marker.link)){
                            window.open(marker.link, '_blank');
                        }else{
                            router.push(marker.link)
                        }
                    }} className={`facility-marker ${marker.regionColor} div-${blockTypeClass}-link`}>
                    {marker.text}
                </div>
            </Marker>
        })
    }

    return <GoogleMapReact
        bootstrapURLKeys={{ key: `${settings().googleMaps?.apiKey}`}}
        defaultCenter={defaultProps.center}
        // defaultZoom={defaultProps.zoom}
        zoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        key={key}
        onGoogleApiLoaded={({ map, maps }) => {
            mapRef.current = map;
            handleApiLoaded(map, maps);
            detectZoomChange(map,maps);
        }}
        onChange={onMapChange}
    >
        {!regionalMap && !metroMap && renderLocations(renderMarkers)}
        {regionalMap && renderMetros(renderMarkers)}
        {metroMap && renderFacilities(renderMarkers)}
    </GoogleMapReact>
}
export default Map;